/**
 * Here we need to auto load an organization's hardware licenses if they are an
 * organization admin. If they are a LAS admin, we need to provide them a dropdown
 * to select the organization for which they would like to view hardware licenses.
 */
import { Module, Action } from '@vuex/decorators';
import { TablePanel } from 'table-panel';

import { RoleId } from '@auth';
import { HardwareLicense } from '@models';
import { alert } from '@services/alert';
import { ensureError } from '@tools/ensure-error';
import { createOptions } from '@utils/select-options';
import { store } from '@store';
import { faArrowDown } from '@icons/solid/faArrowDown';

const columns: TablePanel.Column<HardwareLicense>[] = [
  {
    key: 'key',
    label: 'Key',
    value: 'key',
    component: 'TableCellLicenseKey',
  },
  {
    key: 'organization',
    label: 'Organization',
    value: (license) => license.organization.name,
    hidden: ({ selectedRole }) => selectedRole?.roleId !== RoleId.LasAdmin,
  },
  {
    key: 'description',
    label: 'Description',
    value: 'description',
  },
  {
    key: 'issuedDate',
    label: 'Issued Date',
    type: 'dateTime',
    value: 'issuedDate',
  },
  {
    key: 'expirationDate',
    label: 'Expiration',
    type: 'dateTime',
    value: 'expirationDate',
  },
  {
    key: 'user',
    label: 'User',
    value: (license) => {
      if (license.registeredBy.firstName && license.registeredBy.lastName) {
        return `${license.registeredBy.firstName} ${license.registeredBy.lastName}`;
      }
      return license.registeredBy.email;
    },
  },
];

const operations: TablePanel.Row.Operation<HardwareLicense>[] = [
  {
    label: 'Unregister License',
    icon: faArrowDown,
    fn: async (license) => {
      try {
        await store.dispatch('hardwareLicenses/unregister', {
          organizationId: license.organizationId,
          hardwareLicenseKey: license.key,
        });
      } catch (err) {
        return alert.error(ensureError(err).feedback ?? err);
      }
      alert.success('License unregistered successfully');
    },
    hidden: (license) => new Date(license.expirationDate) < new Date(),
  },
];

const filterFields = ['key', 'description', 'organization.name', 'registeredBy.email'];

const filterParams: TablePanel.FilterParam[] = [
  {
    key: 'organizationId',
    label: 'Organization',
    options: async () => {
      if (store.state.me.selectedRole?.roleId !== RoleId.LasAdmin) return [];
      
      const items = await store.dispatch('organizations/list');
      return createOptions.organizations(items);
    },
    hidden: () => store.state.me.selectedRole?.roleId !== RoleId.LasAdmin,
  },
];

@Module({ namespaced: true })
export class HardwareLicensesTablePanel
  extends TablePanel<HardwareLicense>
  implements TablePanel.Props<HardwareLicense> {
  onPageChanged?: TablePanel.OnPageChangedCallback<HardwareLicense>;
  readonly module = 'hardwareLicenses';
  readonly loadAction = 'list';
  readonly loadPageAction = 'loadPage';
  readonly pk = 'key';
  readonly label = 'HARDWARE LICENSES';
  readonly columns = columns;
  readonly operations = operations;
  readonly filterFields = filterFields;
  readonly filterParams = filterParams;
  readonly tableSortType = 'issued';
  readonly tableSortReverse = true;
  readonly progressive = true;
  // readonly loadOnOpen = this.isActiveRole(RoleId.InstitutionAdmin);

  get infoMessage() {
    if (this.isActiveRole(RoleId.LasAdmin)) {
      return 'Please select an organization from the filters dropdown to view hardware licenses';
    }
    return null;
  }

  /**
   * Only available if the user is a LAS Admin or if the user's organization is
   * allowed to use hardware licenses.
   */
  get enabled() {
    if (this.isActiveRole(RoleId.LasAdmin)) return true;
    if (this.activeUser.organization?.allowHardwareLicenses) return true;
    return false;
  }

  @Action
  async load() {
    const { selectedRole } = this.activeUser;

    // For Institution Admins, automatically load their organization's licenses
    if (selectedRole?.roleId === RoleId.InstitutionAdmin && selectedRole.organization) {
      await this.context.dispatch('loadPage', {
        filter: {
          params: {
            organizationId: selectedRole.organization.id
          }
        }
      });
      return;
    }

    // For LAS Admins, wait for organization selection
    if (selectedRole?.roleId === RoleId.LasAdmin) {
      this.context.commit('hardwareLicenses/CLEAR', null, { root: true });
      return;
    }
  }

  @Action
  async loadPage(payload: { filter: { params: { organizationId: string } } }) {
    // Implement the loading logic here
    return await this.context.dispatch('hardwareLicenses/loadPage', payload, { root: true });
  }
}
