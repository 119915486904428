import { server } from '@api/request';
import * as models from '@models';
import { isNumber, isArray, isObject, isNull } from '@tools/type-guards';
import * as currencies from '@utils/currencies';

type PaginatedOrganizationsResults =
  ZephyrWeb.Pagination.Results<models.Organization>;

/**
 * List all existing organizations.
 *
 * @returns A list of organizations.
 *
 * @see {@link ZephyrApi.Admin.Organizations.listOrganizations `AdminListOrganizations`}
 * API route in `zephyr-serverless`
 */
export async function list() {
  return await server.list('v2/admin/organizations', processOrganizationData);
}

/**
 * {@link search Search organizations} API request namespace.
 */
export namespace OrganizationSearch {
  /**
   * {@link search Search organizations} API request options.
   */
  export interface Options {
    organizationId?: string;
    name?: string;
    order?: string;
    startDate?: string;
    endDate?: string;
    limit?: number;
    startKey?: ZephyrWeb.Pagination.PlacementKey;
  }

  /**
   * {@link search Search organizations} API request response.
   */
  export type Results = PaginatedOrganizationsResults;
}

/**
 * Search for organizations by a specified criteria (paginated).
 *
 * @param options ...
 * @returns `OrganizationSearch.Results` data object.
 */

/**
 * Search for organizations by a specified criteria (paginated).
 *
 * @param options Request options bag.
 * @returns `OrganizationSearch.Results` data object.
 *
 * @see
 * {@link ZephyrApi.Admin.Organizations.searchOrganizations `AdminSearchOrganizations`}
 * API route in `zephyr-serverless`
 */
export async function search(options: OrganizationSearch.Options) {
  return await server.post(
    'v2/admin/organizations/search',
    options,
    processSearchResults,
  );
}

/**
 * {@link get Get organization} API request options.
 */
export interface GetOptions {
  organizationId: models.Organization['id'];
  admin?: boolean;
}

/**
 * Get a specified organization.
 *
 * @param options Request options bag.
 * @returns The specified account.
 *
 * @see {@link ZephyrApi.Admin.Organizations.getOrganization `AdminGetOrganization`}
 * API route in `zephyr-serverless`
 * @see {@link ZephyrApi.Organizations.getOrganization `GetOrganization`} API
 * route in `zephyr-serverless`
 */
export async function get(options: GetOptions) {
  return await server.get(
    options.admin
      ? `v2/admin/organizations/${options.organizationId}`
      : `v2/organizations/${options.organizationId}`,
    processOrganizationData,
  );
}

/**
 * {@link updateCertificationPermissions Update certification permissions} API request options.
 */
export interface UpdateCertificationPermissions {
  organizationId: models.Organization['id'];
  userId: models.User['id'];
  roleId: models.Role['id'];
  certifications: models.Product['id'][];
}

/**
 * Update certification permissions for a user in an organization.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Organizations.updateCertificationPermissions `UpdateCertificationPermissions`} API route in `zephyr-serverless`
 */
export async function updateCertificationPermissions(
  options: UpdateCertificationPermissions,
) {
  const { organizationId, ...data } = options;

  await server.post(
    `v2/organizations/${organizationId}/update-certification-permissions`,
    data,
  );
}

/**
 * {@link create Create organization} API request options.
 */
export interface CreateOptions {
  name: string;
  stripeId?: string;
  logo?: string;
  accentColor?: string;
  logoBGColor?: string;
  billingAddress: models.Organization.Address;
  shippingAddress: models.Organization.Address;
  /**
   * @deprecated
   */
  useAppstream?: boolean;
  /**
   * @deprecated
   */
  appstreamFleetId?: string;
  /**
   * @deprecated
   */
  appstreamStackId?: string;
  /**
   * @deprecated
   */
  allowBulkCreate?: boolean;
  phone: string;
  taxId?: string;
  email: string;
  studentExpirationTime?: number;
  gracePeriod?: number;
  /**
   * @deprecated
   */
  costPerSeat?: models.Organization.SeatPriceInfo;
  priceId?: string;
  /**
   * @deprecated
   */
  upgradeDiscount?: models.Organization.UpgradeDiscount;
  /**
   * @deprecated
   */
  extensionCost?: number;
  nextInvoiceDate?: string;
  billingType?: models.Organization.BillingType;
  resellerId?: unknown;
  billingCycle?: number;
  billingDay?: number;
  paymentCycle?: number;
  droneLogBookStartLocation?: models.Organization.DroneLogBookStartLocation;
  allowAddLicense?: boolean;
  passwordRotationPolicy?: models.Organization.PasswordRotationPolicy;
  isK12?: boolean;
  allowHardwareLicenses?: boolean;
  maxHardwareLicenses?: number;
  hardwareLicenseExpirationDate?: string;
}

/**
 * Create an organization.
 *
 * @param options Request options bag.
 * @returns ...
 *
 * @see {@link ZephyrApi.Admin.Organizations.createOrganization `CreateOrganization`}
 * API route in `zephyr-serverless`
 */
export async function create(options: CreateOptions) {
  return await server.post(
    'v2/admin/organizations',
    options,
    processOrganizationData,
  );
}

/**
 * {@link update Update organization} API request options.
 */
export interface UpdateOptions {
  organizationId: models.Organization['id'];
  stripeId?: string;
  name?: string;
  logo?: string; // base64 encoded
  accentColor?: string;
  logoBGColor?: string;
  billingAddress?: models.Organization.Address;
  shippingAddress?: models.Organization.Address;
  /**
   * @deprecated
   */
  useAppstream?: boolean;
  /**
   * @deprecated
   */
  appstreamFleetId?: string;
  /**
   * @deprecated
   */
  appstreamStackId?: string;
  /**
   * @deprecated
   */
  allowBulkCreate?: boolean;
  phone?: string;
  taxId?: string;
  email?: string;
  studentExpirationTime?: number;
  gracePeriod?: number;
  /**
   * @deprecated
   */
  costPerSeat?: models.Organization.SeatPriceInfo;
  seatCost?: models.Organization.SeatPriceInfo;
  priceId?: string;
  /**
   * @deprecated
   */
  upgradeDiscount?: models.Organization.UpgradeDiscount;
  /**
   * @deprecated
   */
  extensionCost?: number;
  nextInvoiceDate?: string;
  billingType?: models.Organization.BillingType;
  resellerId?: unknown;
  billingCycle?: number;
  billingDay?: number;
  paymentCycle?: number;
  droneLogBookStartLocation?: models.Organization.DroneLogBookStartLocation;
  amountToCreditOrDebit?: number;
  allowAddLicense?: boolean;
  passwordRotationPolicy?: models.Organization.PasswordRotationPolicy;
  isK12?: boolean;
  allowHardwareLicenses?: boolean;
  maxHardwareLicenses?: number;
  hardwareLicenseExpirationDate?: string | null;
}

/**
 * Update an organization.
 *
 * @param options Request options bag.
 * @returns ...
 *
 * @see {@link ZephyrApi.Admin.Organizations.updateOrganization `UpdateOrganization`}
 * API route in `zephyr-serverless`
 */
export async function update(options: UpdateOptions) {
  return await server.post(
    `v2/admin/organizations/${options.organizationId}`,
    options,
    processOrganizationData,
  );
}

/**
 * {@link updateLogo Update organization logo} API request options.
 */
export interface UpdateLogoOptions {
  organizationId: models.Organization['id'];
  logo: ZephyrWeb.ImageInfo;
}

/**
 * Update organization logo.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Organizations.updateLogo `UpdateLogo`} API route in
 * `zephyr-serverless`
 */
export async function updateLogo(options: UpdateLogoOptions) {
  const { organizationId, logo } = options;

  // const formData = new FormData(); formData.append('file', logo);

  return await server.post(`v2/organizations/${organizationId}/update-logo`, {
    logo,
  });
}

/**
 * {@link addSeats Add seat} API request options.
 */
export interface AddSeatsOptions {
  organizationId: models.Organization['id'];
  seats: number;
}

/**
 * Add a specified number of new seats to an organization.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Organizations.addSeats `AddSeats`} API route in
 * `zephyr-serverless`

 */
export async function addSeats(options: AddSeatsOptions) {
  const { organizationId, ...data } = options;

  return await server.post(
    `v2/organizations/${organizationId}/add-seats`,
    data,
  );
}

/**
 * {@link purchaseSeats Purchase seat} API request options.
 */
export type PurchaseSeatsOptions = {
  organizationId: models.Organization['id'];
  seats: number;
  total: number;
  service: 'Standard' | 'Paypal';
  billingCountry?: string | null;
  billingStateProvince?: string | null;
  billingPostalCode?: string | null;
} & ({ nonce: string } | { method: string });

/**
 * Purchase a specified number of new seats to an organization.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Organizations.buySeats `BuySeats`} API route in
 * `zephyr-serverless`
 */
export async function purchaseSeats(options: PurchaseSeatsOptions) {
  const { organizationId, ...props } = options;

  const data: Record<string, unknown> = {
    seats: props.seats,
    service: props.service,
    total: props.total,
  };

  if (props.billingCountry) {
    data.billingCountry = props.billingCountry;
  }

  if (props.billingStateProvince) {
    data.billingStateProvince = props.billingStateProvince;
  }

  if (props.billingPostalCode) {
    data.billingPostalCode = props.billingPostalCode;
  }

  if ('nonce' in props) {
    Object.assign(data, { paymentMethodNonce: props.nonce });
  } else {
    Object.assign(data, { paymentMethodId: props.method });
  }

  return await server.post(
    `v2/organizations/${organizationId}/buy-seats`,
    data,
  );
}

/**
 * {@link giftSeats Gift seat} API request options.
 */
export interface GiftSeatsOptions {
  organizationId: models.Organization['id'];
  seats: number;
}

/**
 * "Gift" a specified number of new seats to an organization.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Admin.Organizations.giftSeats `AdminGiftSeats`} API
 * route in `zephyr-serverless`
 */
export async function giftSeats(options: GiftSeatsOptions) {
  const { organizationId, ...data } = options;
  if (typeof data.seats === 'string') data.seats = parseInt(data.seats, 10);

  return await server.post(
    `v2/admin/organizations/${organizationId}/gift-seats`,
    data,
  );
}

/**
 * {@link removeSeats Remove seats} API request options.
 */
export interface RemoveSeatsOptions {
  organizationId: models.Organization['id'];
  seats: number;
  deductCurrentBalance: boolean;
}

/**
 * "Remove" a specified number of seats from an organization.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Admin.Organizations.removeSeats `AdminRemoveSeats`} API
 * route in `zephyr-serverless`
 */
export async function removeSeats(options: RemoveSeatsOptions) {
  const { organizationId, ...data } = options;

  return await server.post(
    `v2/admin/organizations/${organizationId}/remove-seats`,
    data,
  );
}

/**
 * {@link addProducts Add products} API request options.
 */
export interface AddProductsOptions {
  organizationId: models.Organization['id'];
  products: string[];
}

/**
 * ...
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Admin.Organizations.addProducts `AdminOrganizationAddProducts`}
 * API route in `zephyr-serverless`
 */
export async function addProducts(options: AddProductsOptions) {
  return await server.post(`v2/admin/organizations/add-products`, options);
}

/**
 * {@link removeProducts Remove products} API request options.
 */
export interface RemoveProductsOptions {
  organizationId: models.Organization['id'];
  products: string[];
}

/**
 * ...
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Admin.Organizations.removeProducts `AdminOrganizationRemoveProducts`}
 * API route in `zephyr-serverless`
 */
export async function removeProducts(options: RemoveProductsOptions) {
  return await server.post(`v2/admin/organizations/remove-products`, options);
}

/**
 * {@link addLicenses Add licenses} API request options.
 */
export interface AddLicensesOptions {
  organizationId: string;
  quantity: number;
}

/**
 * Add licenses to an organization.
 *
 * @param options ...
 * @returns  ...
 *
 * @see {@link ZephyrApi.Organizations.addLicenses `AddLicenses`} API route in
 * `zephyr-serverless`
 */
export async function addLicenses(options: AddLicensesOptions) {
  const { organizationId, ...data } = options;

  await server.post(`v2/organizations/${organizationId}/licenses`, data);
}

/**
 * {@link addLicensesAsAdmin Add licenses as admin} API request options.
 */
export interface AddLicensesAsAdminOptions {
  organizationId: string;
  quantity: number;
}

/**
 * Add licenses to an organization as an LAS admin.
 *
 * @param options ...
 * @returns  ...
 *
 * @see {@link ZephyrApi.Admin.Organizations.addLicenses `AdminAddLicenses`} API
 * route in `zephyr-serverless`
 */
export async function addLicensesAsAdmin(options: AddLicensesAsAdminOptions) {
  const { organizationId, ...data } = options;

  await server.post(`v2/admin/organizations/${organizationId}/licenses`, data);
}

/**
 * {@link removeLicenses Remove licenses} API request options.
 */
export interface RemoveLicensesOptions {
  organizationId: string;
  licenses: string[];
}

/**
 * Remove licenses from an organization.
 *
 * @param options ...
 * @returns  ...
 *
 * @see {@link ZephyrApi.Admin.Organizations.adminOrganizationRemoveLicenses `AdminOrganizationRemoveLicenses`}
 * API route in `zephyr-serverless`
 */
export async function removeLicenses(options: RemoveLicensesOptions) {
  const { organizationId, ...data } = options;

  await server.post(
    `v2/admin/organizations/${organizationId}/remove-licenses`,
    data,
  );
}

/**
 * {@link removeUser Remove user} API request options.
 */
export interface RemoveUserOptions {
  organizationId: models.Organization['id'];
  userId: models.User['id'];
}

/**
 * ...
 *
 * @param options Request options bag.
 * @returns ...
 *
 * @see {@link ZephyrApi.Organizations.removeUser `RemoveUser`} API route in
 * `zephyr-serverless`
 */
export async function removeUser(options: RemoveUserOptions) {
  const { organizationId, ...data } = options;

  await server.post(`v2/organizations/${organizationId}/users/remove`, data);
}

/**
 * {@link invite Send invite} API request options.
 */
export interface InviteOptions {
  organizationId: models.Organization['id'];
  roleId: models.Role['roleId'];
  emails: string[];
  expirationDate?: string;
}

/**
 * Send invites for a specific organization to a list of email addresses.
 *
 * @param options Request options bag.
 * @returns ...
 *
 * @see {@link ZephyrApi.Organizations.inviteUser `InviteUser`} API route in
 * `zephyr-serverless`
 */
export async function invite(options: InviteOptions) {
  const { organizationId, ...data } = options;

  return await server.post(`v2/organizations/${organizationId}/invites`, data);
}

/**
 * {@link resendInvites Resend invite} API request options.
 */
export interface ResendInviteOptions {
  organizationId: models.Organization['id'];
  invites: models.Invite['id'][];
}

/**
 * Resend array of unused invites. Also extends invite expiration
 */
export async function resendInvites(options: ResendInviteOptions) {
  const { organizationId, ...data } = options;

  return await server.post(
    `v2/organizations/${organizationId}/invites/resend`,
    data,
  );
}

/**
 * {@link requestStudentUpgrade Request student upgrade} API request options.
 */
export interface RequestStudentUpgradeOptions {
  organizationId: models.Organization['id'];
}

/**
 * ...
 *
 * @param options Request options bag.
 * @returns ...
 *
 * @see {@link ZephyrApi.Organizations.studentUpgrade `StudentUpgrade`} API
 * route in `zephyr-serverless`
 */
export async function requestStudentUpgrade(
  options: RequestStudentUpgradeOptions,
) {
  return await server.post(
    `v2/organizations/${options.organizationId}/student-upgrade`,
    null,
    processStudentUpgradeData,
  );
}

/**
 * {@link prorate Prorate organization} API request options.
 */
export interface ProrateOptions {
  organizationId: models.Organization['id'];
  lineItems: ProrateOptions.LineItem[];
}

export namespace ProrateOptions {
  export interface LineItem {
    productId: string;
    quantity: number;
  }
}

/**
 * Cost breakdown information regarding a specified organization proration.
 */
export interface ProrationSummary {
  subtotal: number;
  tax: number;
  grandTotal: number;
  fullTaxBreakdown: { tax: ProrationSummary.TaxBreakdown };
}

export namespace ProrationSummary {
  export interface JurisdictionsInfo {
    country: string;
    state?: string;
    county?: string;
    city?: string;
  }

  export interface TaxBreakdown {
    order_total_amount: number;
    shipping: number;
    taxable_amount: number;
    amount_to_collect: number;
    rate: number;
    has_nexus: boolean;
    freight_taxable: boolean;
    tax_source: string;
    exemption_type: string | null;
    jurisdictions: JurisdictionsInfo;
    // breakdown?: Breakdown;
    breakdown?: object;
  }
}

/**
 * ...
 *
 * @param options Request options bag.
 * @returns ...
 *
 * @see {@link ZephyrApi.Organizations.prorate `Prorate`} API route in
 * `zephyr-serverless`
 *
 * @deprecated
 */
export async function prorate(options: ProrateOptions) {
  return await server.post<ProrationSummary>(
    'v2/organizations/prorate',
    options,
  );
}

/**
 * {@link updateUserLicenses Update user license} API request options.
 */
export interface UpdateUserLicensesOptions {
  organizationId: models.Organization['id'];
  includeAdmins: boolean;
  includeInstructors: boolean;
  courses?: models.Course['id'][] | null;
}

/**
 * ...
 *
 * @param options Request options bag.
 * @returns ...
 *
 * @see {@link ZephyrApi.Admin.Organizations.updateUserLicenses `AdminUpdateUserLicenses`}
 * API route in `zephyr-serverless`
 */
export async function updateUserLicenses(options: UpdateUserLicensesOptions) {
  return await server.post(
    `v2/admin/organizations/${options.organizationId}/update-user-licenses`,
    options,
  );
}

/**
 * {@link purchaseOtherParty Purchase other party} API request options.
 */
export type PurchaseOtherPartyOptions = {
  organizationId: models.Organization['id'];
  email: string;
  phone?: string;
  cart: { productId: string; quantity: number }[];
  billingFirst?: string;
  billingLast?: string;
  billingAddress1?: string;
  billingAddress2?: string;
  billingAddress3?: string;
  billingCity?: string;
  billingStateProvince?: string;
  billingCountry?: string;
  billingPostalCode?: string;
  shippingFirst?: string;
  shippingLast?: string;
  shippingAddress1?: string;
  shippingAddress2?: string;
  shippingAddress3?: string;
  shippingCity?: string;
  shippingStateProvince?: string;
  shippingCountry?: string;
  shippingPostalCode?: string;
  expedited: boolean;
  total: number;
  targetUsers: models.User['id'][];
  paymentMethodNonce?: string;
  paymentMethodId?: models.PaymentMethod['id'];
  service: 'Standard' | 'Paypal';
} & ({ nonce: string } | { method: string });

/**
 * ...
 *
 * @param options Request options bag.
 * @returns
 */
export async function purchaseOtherParty(options: PurchaseOtherPartyOptions) {
  const { organizationId, ...props } = options;

  const data: Record<string, unknown> = {
    service: props.service,
    total: props.total,
    cart: props.cart,
    targetUsers: props.targetUsers,
    expedited: props.expedited,
    email: props.email,
    organizationId: organizationId,
  };

  if (props.billingCountry) {
    data.billingCountry = props.billingCountry;
  }

  if (props.billingStateProvince) {
    data.billingStateProvince = props.billingStateProvince;
  }

  if (props.billingPostalCode) {
    data.billingPostalCode = props.billingPostalCode;
  }

  if ('nonce' in props) {
    Object.assign(data, { paymentMethodNonce: props.nonce });
  } else {
    Object.assign(data, { paymentMethodId: props.method });
  }

  return await server.post(`v2/organizations/purchase-other-party`, data);
}

type PaginatedHardwareLicensesResults =
  ZephyrWeb.Pagination.Results<models.HardwareLicense>;

export namespace HardwareLicenseSearch {
  export interface Options {
    organizationId?: string;
    limit?: number;
    startKey?: ZephyrWeb.Pagination.PlacementKey;
  }

  export type Results = PaginatedHardwareLicensesResults;
}

export interface GetHardwareLicensesOptions {
  organizationId: models.Organization['id'];
  hardwareLicenseKey?: string;
  limit?: number;
  startKey?: ZephyrWeb.Pagination.PlacementKey;
  order?: string; // 'ASC', 'DESC'
}

/**
 * Get hardware licenses for a specified organization with pagination.
 * 
 * @param options Request options bag.
 * @returns Paginated results of hardware licenses belonging to the organization.
 */
export async function getHardwareLicenses(options: GetHardwareLicensesOptions) {

  return await server.post(
    `v2/organizations/${options.organizationId}/hardware-licenses/search`, options,
    processHardwareLicenseSearchResults
  );
}

// Unregister Hardware License
export interface UnregisterHardwareLicenseOptions {
  organizationId: models.Organization['id'];
  hardwareLicenseKey: models.HardwareLicense['key'];
}

/**
 * Unregister a hardware license for a specified organization.
 * 
 * @param options Request options bag.
 * @returns ...
 */
export async function unregisterHardwareLicense(options: UnregisterHardwareLicenseOptions) {
  return await server.post(
    `v2/organizations/${options.organizationId}/hardware-licenses/unregister`,
    options,
  );
}

/**
 * {@link del Delete organization} API request options.
 */
export interface DeleteOptions {
  organizationId: models.Organization['id'];
}

/**
 * Delete a specified organization.
 *
 * @param options Request options bag.
 *
 * @see {@link ZephyrApi.Admin.Organizations.deleteOrganization `DeleteOrganization`}
 * API route in `zephyr-serverless`
 */
export async function del(options: DeleteOptions) {
  await server.delete(`v2/admin/organizations/${options.organizationId}`);
}

//#region Helper Functions

/**
 * Determine if a value is a valid {@link models.Organization Organization}.
 *
 * @param value The value to check.
 * @returns `true` if the value is a valid
 * {@link models.Organization Organization}, otherwise `false`.
 */
function isValidOrganizationData(value: unknown): value is models.Organization {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * Process data received from a request expected to be an
 * {@link models.Organization Organization}.
 *
 * @param data Data received from the request.
 * @returns The processed data value.
 */
function processOrganizationData(data: unknown) {
  if (!isValidOrganizationData(data)) {
    throw new Error('Invalid organization data item.');
  }

  // If the organization has a number value for `costPerSeat`, it means it's
  // it is an old entry that hasn't been migrated to the cost-per-seat info
  // object that the current organization model uses. For now, convert it to
  // the expected value (amount was traditionally in usd).
  if (isNumber(data.seatCost)) {
    data.seatCost = {
      id: `org_${data.name.toLowerCase()}_cps_id`,
      amount: currencies.convertUnit(data.seatCost, 'usd', 'primary'),
      currency: 'usd',
      active: true,
    };

    // eslint-disable-next-line no-console
    console.warn(
      `[processOrganizationData] organization "${data.name}" has legacy "costPerSeat" in formation, which was converted to the expected object format.`,
    );
  }

  return { ...data } as models.Organization;
}

/**
 * ...
 */
export interface StudentUpgradePackage {
  discount: Discount['id'];
  cart: models.Product[];
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function isValidStudentUpgradeData(
  value: unknown,
): value is StudentUpgradePackage {
  if (!isObject(value)) return false;

  // TEMP: Use a more strict validation.

  return true;
}

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function processStudentUpgradeData(data: unknown) {
  if (!isValidStudentUpgradeData(data)) {
    throw new Error('Invalid student upgrade data item.');
  }

  return { ...data } as StudentUpgradePackage;
}

/**
 * ...
 *
 * @param value ...
 * @returns ...
 */
function isValidSearchResults(
  value: unknown,
): value is OrganizationSearch.Results {
  return (
    isObject(value) &&
    isArray(value.items) &&
    (isObject(value.lastEvaluatedKey) || isNull(value.lastEvaluatedKey))
  );
}

/**
 * ...
 *
 * @param data ...
 * @returns ...
 */
function processSearchResults(data: unknown) {
  if (!isValidSearchResults(data)) {
    throw new Error('Invalid search result data.');
  }

  return data;
}

/**
 * 
 */
function isValidPaginatedHardwareLicensesResults(value: unknown): value is HardwareLicenseSearch.Results {
  return (
    isObject(value) &&
    isArray(value.items) &&
    (isObject(value.lastEvaluatedKey) || isNull(value.lastEvaluatedKey))
  );
}

/**
 * 
 */
function processHardwareLicenseSearchResults(data: unknown) {
  if (!isValidPaginatedHardwareLicensesResults(data)) {
    throw new Error('Invalid hardware licenses search result data.');
  }

  // Convert Unix timestamps to ISO dates
  data.items = data.items.map(item => ({
    ...item,
    expirationDate: new Date(item.expiration * 1000).toISOString(),
    issuedDate: new Date(item.issued * 1000).toISOString()
  }));

  return data;
}

//#endregion Helper Functions
