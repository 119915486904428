import { Action, Module, Mutation, VuexModule } from '@vuex/decorators';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import type { TablePanel } from 'table-panel';

import { api } from '@api';
import { HardwareLicense, Organization } from '@models';
import { Root } from '@store';

declare module '@vuex/core' {
  export interface Getters {
    'hardwareLicenses/findByKey': HardwareLicenses['findByKey'];
  }

  export interface CommitMap {
    'hardwareLicenses/SET': HardwareLicenses['SET'];
    'hardwareLicenses/SET_LAST_EVALUATED_KEY': HardwareLicenses['SET_LAST_EVALUATED_KEY'];
    'hardwareLicenses/DELETE': HardwareLicenses['DELETE'];
    'hardwareLicenses/CLEAR': HardwareLicenses['CLEAR'];
  }

  export interface DispatchMap {
    'hardwareLicenses/loadPage': HardwareLicenses['loadPage'];
    'hardwareLicenses/list': HardwareLicenses['list'];
    'hardwareLicenses/unregister': HardwareLicenses['unregister'];
  }
}

@Module({ namespaced: true })
export class HardwareLicenses
  extends VuexModule<HardwareLicenses.State, Root.State>
  implements HardwareLicenses.State
{
  items: HardwareLicense[] = [];
  lastEvaluatedKey: ZephyrWeb.Pagination.PlacementKey | null = null;
  allResultsLoaded = false;

  get findByKey() {
    return (key: string) => find(this.items, { key });
  }

  @Mutation
  SET(options: HardwareLicenses.SetMutationOptions) {
    this.items = options.data;
  }

  @Mutation
  SET_LAST_EVALUATED_KEY(options: {
    lastEvaluatedKey: ZephyrWeb.Pagination.PlacementKey | null;
  }) {
    this.lastEvaluatedKey = options.lastEvaluatedKey;
  }

  @Mutation
  DELETE(options: HardwareLicenses.DeleteMutationOptions) {
    const index = findIndex(this.items, { key: options.hardwareLicenseKey });

    if (index === -1) {
      throw new Error(`Hardware license with key ${options.hardwareLicenseKey} not found.`);
    }

    this.items = this.items.filter((_, i) => i !== index);
  }

  @Mutation
  CLEAR() {
    this.items = [];
  }

  @Action
  async loadPage(options: TablePanel.LoadPageOptions<HardwareLicense>) {
    const { me } = this.context.rootState;
    const organizationId = options.filter.params?.['organizationId'] || me.organization?.id;

    if (!organizationId) {
      return {
        items: [],
        lastEvaluatedKey: null,
      };
    }

    const searchOptions: api.organizations.GetHardwareLicensesOptions = {
      organizationId: organizationId as string,
      limit: 25,
    };

    let data: HardwareLicense[] = [];
    if (this.lastEvaluatedKey && !options.clearPrevious) {
      searchOptions.startKey = this.lastEvaluatedKey;
      data = [...this.items];
    } else {
      data = [];
    }

    const res = await api.organizations.getHardwareLicenses(searchOptions);
    const org = await this.context.rootGetters['organizations/findById'](organizationId as string) as Organization | null;

    // Enhance each license with organization data
    const enhancedItems = (res.items ?? []).map(item => ({
      ...item,
      organization: {
        id: organizationId as string,
        name: org?.name ?? 'Unknown Organization'
      }
    }));

    data.push(...enhancedItems);

    this.context.commit('SET', { data });
    this.context.commit('SET_LAST_EVALUATED_KEY', {
      lastEvaluatedKey: res.lastEvaluatedKey,
    });

    return res;
  }

  @Action
  async list() {
    const { me } = this.context.rootState;
    
    if (!me.organization?.id) {
      return [];
    }

    const res = await api.organizations.getHardwareLicenses({
      organizationId: me.organization.id,
      limit: 25,
    });

    const data = res.items ?? [];
    this.context.commit('SET', { data });

    return this.items;
  }

  @Action
  async unregister(options: api.organizations.UnregisterHardwareLicenseOptions) {
    await api.organizations.unregisterHardwareLicense(options);
    this.context.commit('DELETE', { hardwareLicenseKey: options.hardwareLicenseKey });
  }
}

export namespace HardwareLicenses {
  export interface State {
    items: HardwareLicense[];
  }

  export interface SetMutationOptions {
    data: HardwareLicense[];
  }

  export type DeleteMutationOptions = api.organizations.UnregisterHardwareLicenseOptions;
  export type RevokeActionOptions = api.organizations.UnregisterHardwareLicenseOptions;
}

export default HardwareLicenses;
