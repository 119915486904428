import { Module, VuexModule } from '@vuex/decorators';

import { Root } from '@store';

import { AllTransactionsTablePanel } from './all-transactions';
import { AnnouncementTablePanel } from './announcements';
import { ApiKeyTablePanel } from './api-keys';
import { AssignmentsTablePanel } from './assignments';
import { CertificationTablePanel } from './certifications';
import { ClaimedCertificationsTablePanel } from './certifications-claimed';
import { CourseAnnouncementsTablePanel } from './course-announcements';
import { CourseAssignmentsTablePanel } from './course-assignments';
import { CourseAssignmentTablePanel } from './course-assignment';
import { CourseStudentsTablePanel } from './course-students';
import { CourseTablePanel } from './courses';
import { DLCTablePanel } from './dlc';
import { DroneTablePanel } from './drones';
import { ExtensionCodesTablePanel } from './extension-codes';
import { HardwareLicensesTablePanel } from './hardware-licenses';
import { OrganizationInvoicesTablePanel } from './organization-invoices';
import { OrganizationTransactionsTablePanel } from './organization-transactions';
import { OrganizationTablePanel } from './organizations';
import { OutputLogEventsTablePanel } from './output-log-event';
import { InvitesTablePanel } from './invites';
import { LicensesTablePanel } from './licenses';
import { ModuleTablePanel } from './modules';
import { MyTransactionsTablePanel } from './my-transactions';
import { MyCertificationsTablePanel } from './certifications-mine';
import { ProductsTablePanel } from './products';
import { PromotionCodesTablePanel } from './promotion-codes';
import { ResellerTablePanel } from './resellers';
import { ReviewableCertificationsTablePanel } from './certifications-reviewable';
import { ReviewerCertificationsTablePanel } from './certifications-reviewer';
import { ManageCertificationsTablePanel } from './certifications-manage';
import { SceneTablePanel } from './scenes';
import { UserTablePanel } from './users';
import { UnityCatalogTablePanel } from './unity-catalogs';

/** ... */
const MODULES = {
  allTransactions: AllTransactionsTablePanel,
  announcements: AnnouncementTablePanel,
  apiKeys: ApiKeyTablePanel,
  assignments: AssignmentsTablePanel,
  certifications: CertificationTablePanel,
  claimedCertifications: ClaimedCertificationsTablePanel,
  courseAnnouncements: CourseAnnouncementsTablePanel,
  courseAssignment: CourseAssignmentTablePanel,
  courseAssignments: CourseAssignmentsTablePanel,
  courseStudents: CourseStudentsTablePanel,
  courses: CourseTablePanel,
  dlc: DLCTablePanel,
  drones: DroneTablePanel,
  extensionCodes: ExtensionCodesTablePanel,
  hardwareLicenses: HardwareLicensesTablePanel,
  organizationInvoices: OrganizationInvoicesTablePanel,
  organizationTransactions: OrganizationTransactionsTablePanel,
  organizations: OrganizationTablePanel,
  outputLogEvents: OutputLogEventsTablePanel,
  invites: InvitesTablePanel,
  licenses: LicensesTablePanel,
  mods: ModuleTablePanel,
  myTransactions: MyTransactionsTablePanel,
  myCertifications: MyCertificationsTablePanel,
  products: ProductsTablePanel,
  promotionCodes: PromotionCodesTablePanel,
  resellers: ResellerTablePanel,
  reviewableCertifications: ReviewableCertificationsTablePanel,
  reviewerCertifications: ReviewerCertificationsTablePanel,
  manageCertifications: ManageCertificationsTablePanel,
  scenes: SceneTablePanel,
  users: UserTablePanel,
  unityCatalogs: UnityCatalogTablePanel,
};

/** ... */
type TablePanelModules = typeof MODULES;

// declare module '@vuex/core' {
//   type TablePanelEnabledGetter = `tablePanels/${TablePanels.ModuleKey}/enabled`;

//   // eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
//   export interface Getters {
//     [P: TablePanelEnabledGetter]: TablePanel<unknown>['enabled'];
//   }
// }

/**
 * ...
 */
@Module({ namespaced: true })
export class TablePanels
  extends VuexModule<TablePanels.State, Root.State>
  implements TablePanels.State
{
  readonly allTransactions!: AllTransactionsTablePanel;
  readonly announcements!: AnnouncementTablePanel;
  readonly assignments!: AssignmentsTablePanel;
  readonly apiKeys!: ApiKeyTablePanel;
  readonly certifications!: CertificationTablePanel;
  readonly claimedCertifications!: ClaimedCertificationsTablePanel;
  readonly courseAnnouncements!: CourseAnnouncementsTablePanel;
  readonly courseAssignment!: CourseAssignmentTablePanel;
  readonly courseAssignments!: CourseAssignmentsTablePanel;
  readonly courseStudents!: CourseStudentsTablePanel;
  readonly courses!: CourseTablePanel;
  readonly dlc!: DLCTablePanel;
  readonly drones!: DroneTablePanel;
  readonly extensionCodes!: ExtensionCodesTablePanel;
  readonly hardwareLicenses!: HardwareLicensesTablePanel;
  readonly organizationInvoices!: OrganizationInvoicesTablePanel;
  readonly organizationTransactions!: OrganizationTransactionsTablePanel;
  readonly organizations!: OrganizationTablePanel;
  readonly outputLogEvents!: OutputLogEventsTablePanel;
  readonly invites!: InvitesTablePanel;
  readonly licenses!: LicensesTablePanel;
  readonly mods!: ModuleTablePanel;
  readonly myTransactions!: MyTransactionsTablePanel;
  readonly myCertifications!: MyCertificationsTablePanel;
  readonly products!: ProductsTablePanel;
  readonly promotionCodes!: PromotionCodesTablePanel;
  readonly resellers!: ResellerTablePanel;
  readonly reviewableCertifications!: ReviewableCertificationsTablePanel;
  readonly reviewerCertifications!: ReviewerCertificationsTablePanel;
  readonly manageCertifications!: ManageCertificationsTablePanel;
  readonly scenes!: SceneTablePanel;
  readonly users!: UserTablePanel;
  readonly unityCatalogs!: UnityCatalogTablePanel;

  static modules = MODULES;
}

export namespace TablePanels {
  /** ... */
  export type Modules = {
    [P in keyof TablePanelModules]: InstanceType<TablePanelModules[P]>;
  };

  /** ... */
  export type ModuleKey = keyof Modules;

  /** ... */
  export type ModuleValue = Modules[ModuleKey];

  /** ... */
  export type State = Modules;
}

export default TablePanels;
